import { put, call, fork, take, takeLatest, takeEvery, delay } from 'redux-saga/effects';
// import FileDownload from 'js-file-download';
import { SubmissionError } from 'redux-form';

import { getWithdrawalSystemList, getDepositSystemList } from 'common/_api';
import { REQUEST } from 'redux-config/reduxHelpers';
import { acceptError } from 'common/_redux';
import { sendYandexMetrikaEvent } from 'common/utils';
import { setRedirect } from '../../_redux/actions';

import * as api from '../api';
import * as ActionTypes from './actions';

export function* getDepositSystemListSaga() {
  try {
    const { data } = yield call(getDepositSystemList);
    yield put(ActionTypes.getDepositSystemList.success(data));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getDepositSystemList.failure());
  }
}

export function* getDepositSystemListWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_DEPOSIT_SYSTEM_LIST[REQUEST]);
    yield call(getDepositSystemListSaga);
  }
}

export function* getWithdrawalSystemListSaga() {
  try {
    const { data } = yield call(getWithdrawalSystemList);
    yield put(ActionTypes.getWithdrawalSystemList.success(data));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getWithdrawalSystemList.failure());
  }
}

export function* getWithdrawalSystemListWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_WITHDRAWAL_SYSTEM_LIST[REQUEST]);
    yield call(getWithdrawalSystemListSaga);
  }
}

export function* createPaymentsSaga({ account, operation, amount, currency, paymentMethod, saveRequisites }) {
  console.log('Before before');
  try {
    const { data, status } = yield call(api.makeDeposit, {
      request: {
        amount: { amount, currency },
        operation,
        account,
        saveRequisites,
      },
      paymentMethod,
    });

    console.log('before', { data, status });
    if (status < 300) {
      yield put(ActionTypes.makeDeposit.success(data));
      sendYandexMetrikaEvent(operation);
    } else {
      let error = data.detail ? { _error: data.detail } : data;
      if (data.customSecondStepFilterError) {
        error = { _error: data.customSecondStepFilterError };
      }

      yield put(ActionTypes.makeDeposit.failure(new SubmissionError(error)));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.makeDeposit.failure());
  }
}

export function* createPaymentsWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.makeDeposit.REQUEST);
    yield call(createPaymentsSaga, payload);
  }
}

export function* createWithdrawSaga({ account, operation, amount, currency, paymentMethod, saveRequisites }) {
  try {
    const { data, status } = yield call(api.makeWithdraw, {
      request: {
        amount: { amount, currency },
        operation,
        account,
        saveRequisites,
      },
      paymentMethod,
    });
    if (status < 300) {
      yield put(ActionTypes.makeWithdraw.success(data));
      sendYandexMetrikaEvent(operation);
    } else {
      let error = data.detail ? { _error: data.detail } : data;
      if (data.customSecondStepFilterError) {
        error = { _error: data.customSecondStepFilterError };
      }
      yield put(acceptError(data.details || '', true));
      yield put(ActionTypes.makeWithdraw.failure(new SubmissionError(error)));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.makeWithdraw.failure());
  }
}

export function* createWithdrawWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.makeWithdraw.REQUEST);
    yield call(createWithdrawSaga, payload);
  }
}

export function* confirmPaymentsOperationSaga(values) {
  try {
    const { data, status } = yield call(api.confirmPaymentsOperation, values);
    if (data.redirect_url) {
      window.location.replace(data.redirect_url);
    }

    if (status < 300) {
      yield put(ActionTypes.confirmPaymentsOperation.success(data));
    } else {
      const error = data.detail ? { _error: data.detail } : data;
      yield put(ActionTypes.confirmPaymentsOperation.failure(new SubmissionError(error)));
      const errorMessage = data.detail;
      yield put(acceptError(errorMessage, true));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.confirmPaymentsOperation.failure());
  }
}

export function* confirmPaymentsOperationWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.confirmPaymentsOperation.REQUEST);
    yield call(confirmPaymentsOperationSaga, payload);
  }
}

export function* finishPaymentsSaga(values) {
  try {
    const { data, status } = yield call(api.finishPayment, values);

    if (status < 300) {
      yield put(ActionTypes.sendRequisites.success(data));
      yield put(ActionTypes.finishPayment.success(data));
      sendYandexMetrikaEvent(values.operation);
    } else {
      const error = data.detail ? { _error: data.detail } : data;
      const textError = data.details || data.detail || data[0] || '';
      yield put(acceptError(textError, true));
      yield put(ActionTypes.finishPayment.failure(new SubmissionError(error)));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.finishPayment.failure());
  }
}

export function* finishPaymentsWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.finishPayment.REQUEST);
    yield call(finishPaymentsSaga, payload);
  }
}

export function* sendRequisitesSaga(values) {
  try {
    const { isOnlyRequisites } = values;
    const { data, status } = yield call(api.sendRequisites, { requisitesForm: values.requisitesForm }, values.id);
    if (status < 300) {
      if (!isOnlyRequisites) {
        yield call(finishPaymentsSaga, values);
      } else {
        yield put(ActionTypes.sendRequisites.success(data));
      }
    } else {
      const error = data.detail ? { _error: data.detail } : data;
      yield put(ActionTypes.sendRequisites.failure(new SubmissionError(error)));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.sendRequisites.failure());
  }
}

export function* sendRequisitesWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.sendRequisites.REQUEST);
    yield call(sendRequisitesSaga, payload);
  }
}

export function* resendPaymentConfirmationMessageSaga(id) {
  try {
    const { data, status } = yield call(api.resendPaymentConfirmationMessage, id);

    if (status < 300) {
      yield put(ActionTypes.resendPaymentConfirmationMessage.success(data));
    } else {
      const error = data.detail ? { _error: data.detail } : data;
      yield put(ActionTypes.resendPaymentConfirmationMessage.failure(error));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.resendPaymentConfirmationMessage.failure());
  }
}

export function* resendPaymentConfirmationMessageWatcherSaga() {
  while (true) {
    const { id } = yield take(ActionTypes.RESEND_PAYMENT_CONFIRMATION_MESSAGE[REQUEST]);
    yield call(resendPaymentConfirmationMessageSaga, id);
  }
}

export function* getPaymentsSaga({ id }) {
  try {
    const { status, data } = yield call(api.getPayments, id);
    if (status < 300) {
      yield put(ActionTypes.getPayments.success(data));
    } else {
      yield put(setRedirect());
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getPayments.failure());
  }
}

export function* getPaymentsWatcherSaga() {
  yield takeLatest(ActionTypes.GET_PAYMENTS[REQUEST], getPaymentsSaga);
}

export function* getPaymentsInvoiceSaga({ id }) {
  try {
    const { data } = yield call(api.getPaymentsInvoice, id);
    const invoice = new Blob([data], { type: 'application/pdf' });
    const invoiceURL = URL.createObjectURL(invoice);
    window.open(invoiceURL);
    yield put(ActionTypes.getPaymentsInvoice.success(data));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getPaymentsInvoice.failure());
  }
}

export function* getPaymentsInvoiceWatcherSaga() {
  yield takeLatest(ActionTypes.GET_PAYMENTS_INVOICE[REQUEST], getPaymentsInvoiceSaga);
}

export function* getPaymentsListSaga({ search }) {
  try {
    const { data } = yield call(api.getPaymentsList, search);
    yield put(ActionTypes.getPaymentsList.success(data));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getPaymentsList.failure());
  }
}

export function* getPaymentsListWatcherSaga() {
  while (true) {
    const { search } = yield take(ActionTypes.GET_PAYMENTS_LIST[REQUEST]);
    yield call(getPaymentsListSaga, search);
  }
}

export function* getInOutListSaga({ search }) {
  try {
    const { data } = yield call(api.getInOutList, search);
    yield put(ActionTypes.getInOutList.success(data));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getInOutList.failure());
  }
}

export function* getInOutListWatcherSaga() {
  while (true) {
    const { search } = yield take(ActionTypes.GET_IN_OUT_LIST[REQUEST]);
    yield call(getInOutListSaga, search);
  }
}

export function* cancelWithdrawalSaga({ id }) {
  try {
    yield call(api.cancelWithdrawal, id);
    yield put(ActionTypes.cancelWithdrawal.success());
    const EVENT_NAME = 'Cancel withdrawal';
    sendYandexMetrikaEvent(EVENT_NAME);
    yield call(getPaymentsSaga, { id });
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.cancelWithdrawal.failure());
  }
}

export function* cancelWithdrawalWatcherSaga() {
  yield takeEvery(ActionTypes.CANCEL_WITHDRAWAL[REQUEST], cancelWithdrawalSaga);
}

export function* userGetEditHistorySaga(id) {
  try {
    const { status, data } = yield call(api.userGetEditHistory, id);
    if (status < 300) {
      yield put(ActionTypes.userGetEditHistory.success(data));
    } else {
      yield put(ActionTypes.userGetEditHistory.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.userGetEditHistory.failure());
    throw e;
  }
}

export function* userGetEditHistoryWatcherSaga() {
  while (true) {
    const { id } = yield take(ActionTypes.USER_GET_EDIT_HISTORY[REQUEST]);
    yield call(userGetEditHistorySaga, id);
  }
}

export function* uploadPaymentProofSaga(id, file) {
  try {
    const response = yield call(api.uploadPaymentProof, id, file);
    if (response.status === 413) {
      const error = { file: 'File is too large' };
      yield put(ActionTypes.uploadPaymentProof.failure(error));
    } else if (response.status === 403) {
      yield put(ActionTypes.uploadPaymentProof.failure(response.data.detail));
    } else {
      yield put(ActionTypes.uploadPaymentProof.success(id));
      yield delay(1000);
      yield call(getPaymentsSaga, { id });
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.uploadPaymentProof.failure());
  }
}

export function* uploadPaymentProofWatcherSaga() {
  while (true) {
    const { id, file } = yield take(ActionTypes.UPLOD_PAYMENT_PROOF[REQUEST]);
    yield call(uploadPaymentProofSaga, id, file);
  }
}

export function* makePaymentLpaSaga(payload) {
  try {
    const { status, data } = yield call(api.makePaymentLpa, payload);
    if (status < 300) {
      yield put(ActionTypes.makePaymentLpa.success(data));
    } else {
      yield put(ActionTypes.makePaymentLpa.failure(new SubmissionError(data)));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.makePaymentLpa.failure());
  }
}

export function* makePaymentLpaWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.makePaymentLpa.REQUEST);
    yield call(makePaymentLpaSaga, payload);
  }
}

export function* getAvailableLpasSaga(slug) {
  try {
    const { status, data } = yield call(api.getAvailableLpas, slug);
    if (status < 300) {
      yield put(ActionTypes.getAvailableLpas.success(data));
    } else {
      yield put(acceptError(data[0]));
      yield put(ActionTypes.getAvailableLpas.failure(data[0]));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getAvailableLpas.failure());
    throw e;
  }
}

export function* getAvailableLpasWatcherSaga() {
  while (true) {
    const { slug } = yield take(ActionTypes.GET_AVAILABLE_LPAS[REQUEST]);
    yield call(getAvailableLpasSaga, slug);
  }
}

export function* getLpaClientDealsSaga({ search }) {
  try {
    const { data } = yield call(api.getLpaClientDeals, search);
    yield put(ActionTypes.getLpaClientDeals.success(data));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getLpaClientDeals.failure());
  }
}

export function* getLpaClientDealsWatcherSaga() {
  while (true) {
    const { search } = yield take(ActionTypes.GET_LPA_CLIENT_DEALS[REQUEST]);
    yield call(getLpaClientDealsSaga, search);
  }
}

export function* getLpaAgentDealsSaga({ search }) {
  try {
    const { data } = yield call(api.getLpaAgentDeals, search);
    yield put(ActionTypes.getLpaAgentDeals.success(data));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getLpaAgentDeals.failure());
  }
}

export function* getLpaAgentDealsWatcherSaga() {
  while (true) {
    const { search } = yield take(ActionTypes.GET_LPA_AGENT_DEALS[REQUEST]);
    yield call(getLpaAgentDealsSaga, search);
  }
}

function* getLpaDealSaga({ id }) {
  try {
    const { status, data } = yield call(api.getLpaDeal, id);

    if (status < 300) {
      yield put(ActionTypes.getLpaDeal.success(data));
    } else {
      yield put(ActionTypes.getLpaDeal.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getLpaDeal.failure(e));
  }
}

function* getLpaDealWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_LPA_DEAL[REQUEST]);
    yield call(getLpaDealSaga, data);
  }
}

export default [
  fork(confirmPaymentsOperationWatcherSaga),
  fork(getDepositSystemListWatcherSaga),
  fork(getWithdrawalSystemListWatcherSaga),
  fork(createPaymentsWatcherSaga),
  fork(createWithdrawWatcherSaga),
  fork(finishPaymentsWatcherSaga),
  fork(getPaymentsWatcherSaga),
  fork(getPaymentsInvoiceWatcherSaga),
  fork(getPaymentsListWatcherSaga),
  fork(getInOutListWatcherSaga),
  fork(cancelWithdrawalWatcherSaga),
  fork(userGetEditHistoryWatcherSaga),
  fork(uploadPaymentProofWatcherSaga),
  fork(makePaymentLpaWatcherSaga),
  fork(getAvailableLpasWatcherSaga),
  fork(getLpaClientDealsWatcherSaga),
  fork(getLpaAgentDealsWatcherSaga),
  fork(getLpaDealWatcherSaga),
  fork(resendPaymentConfirmationMessageWatcherSaga),
  fork(sendRequisitesWatcherSaga),
];
