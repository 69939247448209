import React, { useMemo } from 'react';
import { PageLayout, PanelTabs } from 'common/components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import queryString from 'query-string';
import DashboardsPage from '../DashboardsPage';

const DashboardsWrapperPage = ({ addons, profile }) => {
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  const isClientInterface = !window.location.href.includes('backoffice');

  const activeTabSlug = useMemo(
    () => (location.search ? queryString.parse(location.search).tab : 'my'),
    [location.search]
  );

  return (
    <PageLayout captionSlug="boDashboards">
      {addons.includes('managerSystem') && profile.isManager && profile.perms.viewDashboards ? (
        <PanelTabs activeTabSlug={activeTabSlug} location={location} history={history}>
          <DashboardsPage isClientInterface={isClientInterface} textId="justMyClients" slug="my" match={match} />
          {!isClientInterface && (
            <DashboardsPage
              isClientInterface={isClientInterface}
              textId="justAllClients"
              slug="all"
              match={match}
              viewMode="allUsers"
            />
          )}
        </PanelTabs>
      ) : (
        <DashboardsPage
          isClientInterface={isClientInterface}
          textId={isClientInterface ? 'justMyClients' : 'justAllClients'}
          slug={isClientInterface ? 'my' : 'all'}
          match={match}
          viewMode={!isClientInterface && 'allUsers'}
        />
      )}
    </PageLayout>
  );
};

DashboardsWrapperPage.propTypes = {
  profile: PropTypes.object.isRequired,
  addons: PropTypes.array.isRequired,
};

export default connect(state => ({
  currentInterfaceSlug: state.user.currentInterface,
  addons: state.interfaceConfig.addons,
  profile: state.user.profile,
}))(DashboardsWrapperPage);

export { DashboardsWrapperPage };
