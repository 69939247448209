import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { required } from 'redux-form-validators';
import { useLocation } from 'react-router-dom';

import { ReduxTextField, ReduxSelectField, TextAreaField, DefaultButton } from 'common/components';

import { getTemplateList } from 'backoffice/templates/_redux';
import { rejectUserCard } from '../../_redux';

import staticStyles from './style';

const RejectCardsForm = ({
  userId,
  cardId,
  availableLangs,
  templatesList,
  getTemplateList,
  profile,
  toggleModal,
  handleSubmit,
  dispatch,
  submitting,
  invalid,
  error,
}) => {
  const location = useLocation();
  const query = { page_size: 100, ...queryString.parse(location.search) };
  const isUserCanViewTemplateMessages = profile.perms.templateMessagesViewPerm;

  const [isManually, setIsManually] = useState(!isUserCanViewTemplateMessages);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const templates = useMemo(
    () =>
      Array.isArray(templatesList)
        ? templatesList
            .filter(item => item.language === selectedLanguage)
            .map(template => ({ value: template.id, label: template.title }))
        : [],
    [templatesList, selectedLanguage]
  );

  const languages = useMemo(
    () =>
      Array.isArray(availableLangs)
        ? availableLangs.map(language => ({ value: language.slug, label: language.niceName }))
        : [],
    [availableLangs]
  );

  const toggleManually = () => setIsManually(!isManually);

  const submit = useMemo(
    () =>
      handleSubmit(values =>
        rejectUserCard(
          {
            userId,
            cardId,
            ...values,
            ...(isManually
              ? []
              : {
                  title: templatesList.find(item => item.id === selectedTemplate).title,
                  description: templatesList.find(item => item.id === selectedTemplate).text,
                }),
          },
          dispatch
        )
      ),
    [handleSubmit, userId, cardId, templatesList, isManually, selectedTemplate, dispatch]
  );

  /* eslint-disable */
  useEffect(() => {
    if (isUserCanViewTemplateMessages) {
      getTemplateList({ search: query });
    }
  }, [getTemplateList]);
  /* eslint-enable */

  return (
    <div className="RejectModal__content">
      <form className="RejectCardsForm" onSubmit={submit}>
        <div className="RejectCardsForm__inputs-inner">
          {!isManually ? (
            <>
              <Field
                component={ReduxSelectField}
                name="language"
                maxMenuHeight={100}
                textId="justChooseLanguage"
                options={languages}
                validate={[required()]}
                disabled={submitting}
                onChange={setSelectedLanguage}
              />
              <Field
                component={ReduxSelectField}
                name="template"
                maxMenuHeight={100}
                textId="justChooseTemplate"
                options={templates}
                validate={[required()]}
                disabled={submitting || !selectedLanguage}
                onChange={setSelectedTemplate}
              />
            </>
          ) : (
            <>
              <Field
                component={ReduxTextField}
                autoComplete="off"
                type="text"
                name="title"
                textId="justTitle"
                validate={[required()]}
                disabled={submitting}
              />
              <Field
                component={TextAreaField}
                name="description"
                textId="justDescription"
                rows="4"
                cols="150"
                validate={[required()]}
                disabled={submitting}
              />
            </>
          )}
        </div>
        <div className="RejectCardsForm__btns">
          <DefaultButton textId="justSend" type="submit" loading={submitting} disabled={invalid || submitting} filled />
          <DefaultButton
            textId="justCancel"
            type="button"
            onClick={() => toggleModal()}
            loading={submitting}
            disabled={submitting}
          />
          <DefaultButton
            textId={isManually ? 'justBackToTemplates' : 'justTypeManually'}
            type="button"
            onClick={toggleManually}
            disabled={submitting || !isUserCanViewTemplateMessages}
            noBorder
          />
        </div>
        {error && <strong>{error}</strong>}
      </form>
      <style jsx>{staticStyles}</style>
    </div>
  );
};

RejectCardsForm.propTypes = {
  userId: PropTypes.string.isRequired,
  cardId: PropTypes.number.isRequired,
  templatesList: PropTypes.array.isRequired,
  availableLangs: PropTypes.array.isRequired,
  getTemplateList: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  toggleModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

RejectCardsForm.defaultProps = {
  error: '',
};

export default compose(
  connect(
    state => ({
      availableLangs: state.interfaceConfig.availableLangs,
      templatesList: state.backoffice.templates?.templates?.results ?? [],
      profile: state.user.profile,
    }),
    {
      getTemplateList: search => getTemplateList.request(search),
    }
  ),
  reduxForm({
    form: 'RejectCardsForm',
    onSubmitSuccess: (result, dispatch, props) => {
      props.toggleModal();
    },
  })
)(RejectCardsForm);
export { RejectCardsForm };
