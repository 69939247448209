import { SubmissionError } from 'redux-form';
import { put, call, fork, take } from 'redux-saga/effects';

import { acceptError } from 'common/_redux';
import { REQUEST } from 'redux-config/reduxHelpers';

import * as api from '../api';
import * as ActionTypes from './actions';

export function* getInnerTransferOptionsSaga() {
  try {
    const { status, data } = yield call(api.getInnerTransferOptions);
    if (status < 300) {
      yield put(ActionTypes.getInnerTransferOptions.success(data));
    } else {
      yield put(ActionTypes.getInnerTransferOptions.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getInnerTransferOptions.failure());
  }
}

export function* getInnerTransferOptionsWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_INNER_TRANSFER_OPTIONS[REQUEST]);
    yield call(getInnerTransferOptionsSaga);
  }
}

export function* getInnerTransferInfoSaga({ senderAccount, recipientAccount }) {
  try {
    let response;
    if (senderAccount === 'wallet') {
      response = yield call(api.getInnerTransferFromWalletInfo, {
        recipientAccount,
      });
    } else if (recipientAccount === 'wallet') {
      response = yield call(api.getInnerTransferToWalletInfo, {
        senderAccount,
      });
    } else {
      yield call(getInnerTransferOptionsSaga);
      response = yield call(api.getInnerTransferInfo, {
        senderAccount,
        recipientAccount,
      });
    }

    const { status, data } = response;

    if (status < 300) {
      yield put(ActionTypes.getInnerTransferInfo.success(data));
    } else {
      const error = data?.detail ? data.detail : data;
      yield put(ActionTypes.getInnerTransferInfo.failure(error));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getInnerTransferInfo.failure());
  }
}

export function* getInnerTransferInfoWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_INNER_TRANSFER_INFO[REQUEST]);
    yield call(getInnerTransferInfoSaga, data);
  }
}

export function* createInnerTransferSaga({ senderAccount, recipientAccount, sendAmount }) {
  try {
    let response;
    if (senderAccount === 'wallet') {
      response = yield call(api.createFromWalletInnerTransfer, {
        recipientAccount,
        sendAmount: parseFloat(sendAmount),
      });
    } else if (recipientAccount === 'wallet') {
      response = yield call(api.createToWalletInnerTransfer, {
        senderAccount,
        sendAmount: parseFloat(sendAmount),
      });
    } else {
      response = yield call(api.createInnerTransfer, {
        senderAccount,
        recipientAccount,
        sendAmount: parseFloat(sendAmount),
      });
    }

    const { status, data } = response;

    if (status < 300) {
      yield put(ActionTypes.createInnerTransfer.success(data));
    } else if (status === 401) {
      yield put(ActionTypes.createInnerTransfer.failure());
    } else {
      const error = data.detail ? { _error: data.detail } : { _error: data };
      const formError = new SubmissionError(error);
      yield put(ActionTypes.createInnerTransfer.failure(formError));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.createInnerTransfer.failure());
  }
}

export function* createInnerTransferWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.createInnerTransfer.REQUEST);
    yield call(createInnerTransferSaga, payload);
  }
}

export function* getTradersTransferInfoSaga({ senderAccount, recipientAccount, recipient }) {
  try {
    let response;
    if (senderAccount === 'wallet') {
      response = yield call(api.getWalletTransferInfo, {
        recipient,
      });
    } else {
      response = yield call(api.getTradersTransferInfo, {
        senderAccount,
        recipientAccount,
      });
    }
    const { status, data } = response;

    if (status < 300) {
      yield put(ActionTypes.getTradersTransferInfo.success(data));
    } else if (status > 300 && status < 500) {
      const error = data.detail ? data.detail : data;
      yield put(ActionTypes.getTradersTransferInfo.failure(error));
    }
  } catch (e) {
    yield put(ActionTypes.getTradersTransferInfo.failure());
  }
}

export function* getTradersTransferInfoWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_TRADERS_TRANSFER_INFO[REQUEST]);
    yield call(getTradersTransferInfoSaga, data);
  }
}

export function* createTradersTransferSaga({ senderAccount, recipientAccount, recipient, sendAmount }) {
  try {
    let response;
    if (senderAccount === 'wallet') {
      response = yield call(api.createWalletTransfer, {
        recipient,
        sendAmount: parseFloat(sendAmount),
      });
    } else {
      response = yield call(api.createTradersTransfer, {
        senderAccount,
        recipientAccount,
        sendAmount: parseFloat(sendAmount),
      });
    }

    const { status, data } = response;

    if (status < 300) {
      yield put(ActionTypes.createTradersTransfer.success(data));
    } else if (status === 401) {
      yield put(ActionTypes.createTradersTransfer.failure());
    } else if (status === 409) {
      const error = data.detail ? { _error: data.detail } : { _error: data };
      const formError = new SubmissionError(error);
      yield put(ActionTypes.createTradersTransfer.failure(formError));
    } else {
      const formError = new SubmissionError(data);
      yield put(ActionTypes.createTradersTransfer.failure(formError));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.createTradersTransfer.failure());
  }
}

export function* createTradersTransferWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.createTradersTransfer.REQUEST);
    yield call(createTradersTransferSaga, payload);
  }
}

export function* getInternalTransferListSaga({ search }) {
  try {
    const { status, data } = yield call(api.getInternalTransferList, search);
    if (status < 300) {
      yield put(ActionTypes.getInternalTransferList.success(data));
    } else {
      yield put(ActionTypes.getInternalTransferList.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getInternalTransferList.failure());
  }
}

export function* getInternalTransferListWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_INTERNAL_TRANSFER_LIST[REQUEST]);
    yield call(getInternalTransferListSaga, data);
  }
}

export function* getClientTransferDetailsSaga({ transferId }) {
  try {
    const { status, data } = yield call(api.getClientTransferDetails, transferId);
    if (status < 300) {
      yield put(ActionTypes.getClientTransferDetails.success(data));
    } else {
      yield put(ActionTypes.getClientTransferDetails.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getClientTransferDetails.failure());
    throw e;
  }
}

export function* getClientTransferDetailsWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_CLIENT_TRANSFER_DETAILS[REQUEST]);
    yield call(getClientTransferDetailsSaga, data);
  }
}

export function* getLpaTransfersAccountsSaga({ id }) {
  try {
    const response = yield call(api.getLpaTransferAccounts, id);
    const { status, data } = response;

    if (status < 300) {
      yield put(ActionTypes.getLpaTransferAccounts.success(data));
    } else if (status > 300 && status < 500) {
      const error = data.detail ? data.detail : data;
      yield put(ActionTypes.getLpaTransferAccounts.failure(error));
    }
  } catch (e) {
    yield put(ActionTypes.getLpaTransferAccounts.failure());
  }
}

export function* getLpaTransferAccountsWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_LPA_TRANSFER_ACCOUNTS[REQUEST]);
    yield call(getLpaTransfersAccountsSaga, data);
  }
}

export function* createLpaWalletTransferSaga(payload) {
  try {
    const response = yield call(api.createLpaWalletTransfer, payload);
    const { status, data } = response;

    if (status < 300) {
      yield put(ActionTypes.createLpaWalletTransfer.success(data));
    } else if (status === 401) {
      yield put(ActionTypes.createLpaWalletTransfer.failure());
    } else if (status === 409) {
      const error = data.detail ? { _error: data.detail } : { _error: data };
      const formError = new SubmissionError(error);
      yield put(ActionTypes.createLpaWalletTransfer.failure(formError));
    } else {
      const formError = new SubmissionError(data);
      yield put(ActionTypes.createLpaWalletTransfer.failure(formError));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.createLpaWalletTransfer.failure());
  }
}

export function* createLpaWalletTransferWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.createLpaWalletTransfer.REQUEST);
    yield call(createLpaWalletTransferSaga, payload);
  }
}

export function* createLpaTransferSaga(payload) {
  try {
    const response = yield call(api.createLpaTransfer, payload);
    const { status, data } = response;

    if (status < 300) {
      yield put(ActionTypes.createLpaTransfer.success(data));
    } else if (status === 401) {
      yield put(ActionTypes.createLpaTransfer.failure());
    } else if (status === 409) {
      const error = data.detail ? { _error: data.detail } : { _error: data };
      const formError = new SubmissionError(error);
      yield put(ActionTypes.createLpaTransfer.failure(formError));
    } else {
      const formError = new SubmissionError(data);
      yield put(ActionTypes.createLpaTransfer.failure(formError));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.createLpaTransfer.failure());
  }
}

export function* createLpaTransferWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.createLpaTransfer.REQUEST);
    yield call(createLpaTransferSaga, payload);
  }
}

export default [
  fork(getInnerTransferOptionsWatcherSaga),
  fork(getInnerTransferInfoWatcherSaga),
  fork(createInnerTransferWatcherSaga),
  fork(getTradersTransferInfoWatcherSaga),
  fork(createTradersTransferWatcherSaga),
  fork(getTradersTransferInfoWatcherSaga),
  fork(createLpaWalletTransferWatcherSaga),
  fork(createLpaTransferWatcherSaga),
  fork(getLpaTransferAccountsWatcherSaga),
  fork(getInternalTransferListWatcherSaga),
  fork(getClientTransferDetailsWatcherSaga),
];
