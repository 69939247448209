import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { DefaultButton } from 'common/components';
import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const InternalTransferConfirmation = ({ payload, handleSubmit, handleCancel, submitting, error, theme }) => {
  const location = useLocation();
  const history = useHistory();
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const { senderAccount, recipientAccount, sendAmount, receiveAmount } = payload;

  useEffect(
    () => () => {
      if (error) {
        history.push(location?.pathname);
      }
    },
    []
  );
  return (
    <div className="InternalTransferConfirmation">
      <div className="InternalTransferConfirmation__line">
        <FormattedMessage id="justSenderAccount">
          {txt => <span className="InternalTransferConfirmation__title">{txt}</span>}
        </FormattedMessage>
        <span>{`${senderAccount.login} - ${senderAccount.balance.amount}${senderAccount.balance.currency}`}</span>
      </div>
      <div className="InternalTransferConfirmation__line">
        <FormattedMessage id="justRecipientAccount">
          {txt => <span className="InternalTransferConfirmation__title">{txt}</span>}
        </FormattedMessage>
        <span>
          {recipientAccount.balance
            ? `${recipientAccount.login} - ${recipientAccount.balance.amount}${recipientAccount.balance.currency}`
            : recipientAccount}
        </span>
      </div>
      <div className="InternalTransferConfirmation__line">
        <FormattedMessage id="clientSendAmount">
          {txt => <span className="InternalTransferConfirmation__title">{txt}</span>}
        </FormattedMessage>
        <span>{sendAmount}</span>
      </div>
      <div className="InternalTransferConfirmation__line">
        <FormattedMessage id="justWillReceive">
          {txt => <span className="InternalTransferConfirmation__title">{txt}</span>}
        </FormattedMessage>
        <span>{receiveAmount}</span>
      </div>
      {error && (
        <span className="InternalTransferConfirmation__line InternalTransferConfirmation__line--error">{error}</span>
      )}
      <div className="InternalTransferConfirmation__line">
        <DefaultButton
          textId="justConfirm"
          onClick={handleSubmit}
          loading={submitting}
          type="button"
          filled
          disabled={error}
        />
        <DefaultButton textId="justCancel" onClick={handleCancel} type="button" />
      </div>
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

InternalTransferConfirmation.propTypes = {
  payload: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
  theme: PropTypes.object,
};

InternalTransferConfirmation.defaultProps = {
  error: '',
  theme: {},
};

export default withTheme()(InternalTransferConfirmation);
