import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { required } from 'redux-form-validators';

import { ReduxSelectField, DefaultButton } from 'common/components';
import { editUserInfo } from '../../_redux';

import staticStyles from './style';

const EditCountryForm = ({
  id,
  countries,
  countriesAreLoaded,
  toggleModal,
  handleSubmit,
  submitting,
  invalid,
  error,
}) => {
  const dispatch = useDispatch();
  const countriesOptions = countries.map(option => ({
    value: option.gid,
    label: option.name,
  }));

  const submit = useMemo(
    () => handleSubmit(values => editUserInfo({ userId: id, address__country: values.country }, dispatch)),
    [handleSubmit, id, dispatch]
  );

  return (
    <div className="EditCountryForm__content">
      <form className="EditCountryForm" onSubmit={submit}>
        <Field
          component={ReduxSelectField}
          name="country"
          textId="kycNewCountry"
          options={countriesOptions}
          disabled={submitting || !countriesAreLoaded}
          validate={[required()]}
          maxMenuHeight={170}
          searchable
        />
        <div className="EditCountryForm__btns">
          <DefaultButton textId="justSend" type="submit" loading={submitting} disabled={invalid || submitting} filled />
          <DefaultButton
            textId="justCancel"
            type="button"
            onClick={() => toggleModal()}
            loading={submitting}
            disabled={submitting}
          />
        </div>
        {error && <strong>{error}</strong>}
      </form>
      <style jsx>{staticStyles}</style>
    </div>
  );
};

EditCountryForm.propTypes = {
  id: PropTypes.number.isRequired,
  toggleModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

EditCountryForm.defaultProps = {
  error: '',
};

export default compose(
  connect(state => ({
    profile: state.user.profile,
    countries: state.client.kyc.countries,
    countriesAreLoaded: state.client.kyc.countriesAreLoaded,
    currencies: state.backoffice.users.currencies,
    currenciesAreLoaded: state.backoffice.users.currenciesAreLoaded,
  })),
  reduxForm({
    form: 'user-change-country',
    onSubmitSuccess: (result, dispatch, props) => {
      props.toggleModal();
    },
  })
)(EditCountryForm);
export { EditCountryForm };
