import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import {
  BasicModal,
  BaseTable,
  LoginCell,
  MoneyCell,
  ValueLoader,
  DefaultButton,
  Tooltip,
  ActionsButton,
  RowLink,
} from 'common/components';
import { withTheme } from 'common/styling/theme';
import { getAccountTradeLink } from 'client/accounts/_redux';
import { WithdrawalIcon, DepositIcon } from 'common/icons';
import TransferIcon from 'interface/user/_components/LeftNavigationMenuIcons/Icons/TransferIcon';
import DemoDepositForm from '../DemoDepositForm';

import { staticStyles, getDynamicStyles } from './style';

const Leverage = ({ leverage }) => <>{leverage ? <span className="Leverage">1:{leverage}</span> : <span>—</span>}</>;

Leverage.propTypes = {
  leverage: PropTypes.number,
};

Leverage.defaultProps = {
  leverage: null,
};

const WrappedLeverage = ValueLoader(Leverage);

const AccountTable = ({
  data,
  onRowClick,
  paymentButtons,
  webTerminal,
  getAccountTradeLink,
  areTransfersEnabled,
  brokereeConfig,
  equity,
  lang,
  disableDepositForBrokereeManager,
  history,
  addons,
  isDepositEnabled,
  isWithdrawEnabled,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const [selectedDemoAccount, setSelectedDemoAccount] = useState();
  const openDemoDepositModal = id => setSelectedDemoAccount(id);
  const closeDemoDepositModal = () => setSelectedDemoAccount(null);

  const getTooltipMessage = (isBlocked, loadingFailed, action) => {
    if (isBlocked) {
      if (action === 'deposit') {
        return 'clientDepositBlockedAccount';
      }
      if (action === 'withdraw') {
        return 'clientWithdrawBlockedAccount';
      }
      return 'clientTradeBlockedAccount';
    }
    if (loadingFailed) {
      return 'accountBroken';
    }
    return '';
  };

  /* eslint-disable */
  const equityColumn = equity
    ? [
        {
          key: 'justEquity',
          Cell: ({ original: { equity, loading, loadingFailed, id } }) => (
            <RowLink link={`/account/${id}`} isPreviewCell>
              <MoneyCell inTable value={equity} loading={loading} loadingFailed={loadingFailed} />
            </RowLink>
          ),
        },
      ]
    : [];

  const paymentButtonsColumn = paymentButtons
    ? [
        {
          Cell: ({ original: { id, isDemo, isDemoDepositEnabled, isBlocked, loading, loadingFailed } }) =>
            !isDemo ? (
              <div className="paymentButtons">
                {isDepositEnabled && !disableDepositForBrokereeManager && (
                  <ActionsButton
                    buttonId="clientDeposit"
                    disabled={loading || isBlocked || loadingFailed}
                    tooltipId={`clientDeposit-${id}`}
                    tooltipTextId="clientDeposit"
                    watch
                    onClickFunc={e => {
                      e.stopPropagation();
                      history.push('/deposit/');
                    }}>
                    <DepositIcon />
                  </ActionsButton>
                )}
                {isWithdrawEnabled && (
                  <ActionsButton
                    buttonId="clientWithdraw"
                    disabled={loading || isBlocked || loadingFailed}
                    tooltipId={`clientWithdraw-${id}`}
                    tooltipTextId="clientWithdraw"
                    watch
                    onClickFunc={e => {
                      e.stopPropagation();
                      history.push('/withdraw/');
                    }}>
                    <WithdrawalIcon />
                  </ActionsButton>
                )}
                {areTransfersEnabled && addons.includes('walletAccount') && (
                  <ActionsButton
                    buttonId="clientTransfer"
                    disabled={loading || isBlocked || loadingFailed}
                    tooltipId={`clientTransfer-${id}`}
                    tooltipTextId="boInternalTransfer"
                    watch
                    onClickFunc={e => {
                      e.stopPropagation();
                      history.push(`/internalTransfer?tab=transfer&account=${id}`);
                    }}>
                    <TransferIcon />
                  </ActionsButton>
                )}
              </div>
            ) : (
              <div className="paymentButtons">
                <ActionsButton
                  buttonId="clientDeposit"
                  disabled={!isDemoDepositEnabled}
                  tooltipId={`clientDeposit-${id}`}
                  tooltipTextId="clientDeposit"
                  watch
                  onClickFunc={e => {
                    e.stopPropagation();
                    openDemoDepositModal(id);
                  }}>
                  <DepositIcon />
                </ActionsButton>
              </div>
            ),
        },
      ]
    : [];

  const webTerminalButtonColumn =
    webTerminal && paymentButtons
      ? [
          {
            Cell: ({ original: { id, webTerminal, isBlocked, loading, isDemo, loadingFailed, login } }) =>
              webTerminal ? (
                <Tooltip
                  id={`clientTradeBlockedAccountTooltip_${id}`}
                  textId={getTooltipMessage(isBlocked, loadingFailed, 'trade')}>
                  <DefaultButton
                    textId="clientTrade"
                    filled={!isDemo}
                    onClick={e => {
                      e.stopPropagation();
                      getAccountTradeLink({
                        templates: { login, lang },
                        accountId: id,
                      });
                    }}
                    disabled={loading || isBlocked || loadingFailed}
                  />
                </Tooltip>
              ) : (
                <span />
              ),
          },
        ]
      : [];

  const brokereeManagerButtons = disableDepositForBrokereeManager
    ? [
        {
          Cell: ({ original: { accountTypeId, login } }) => (
            <DefaultButton
              textId="justTradePammFund"
              filled
              onClick={e => {
                e.stopPropagation();
                axios
                  .post('/utils/brokeree/login/', { login, accountTypeId, userType: 'Manager' })
                  .then(({ data: tokenResponse }) => {
                    window.open(tokenResponse.link, '_blank');
                  })
                  .catch(() => {
                    window.open(brokereeConfig.app_link, '_blank');
                  });
              }}
            />
          ),
        },
      ]
    : [];

  const columns = [
    {
      key: 'accountLogin',
      Cell: ({ original: { login, accountTypeTitle, id } }) => (
        <RowLink link={`/account/${id}`} isPreviewCell>
          <LoginCell login={login} title={accountTypeTitle} inTable />
        </RowLink>
      ),
    },
    {
      key: 'justBalance',
      Cell: ({ original: { id, balance, loading, loadingFailed } }) => (
        <RowLink link={`/account/${id}`} isPreviewCell>
          <MoneyCell
            tooltipId={`account-table-balance-cell-${id}`}
            inTable
            value={balance}
            loading={loading}
            loadingFailed={loadingFailed}
          />
        </RowLink>
      ),
    },
    ...equityColumn,
    {
      key: 'justLeverage',
      Cell: ({ original: { leverage, loading, loadingFailed, id } }) => (
        <RowLink link={`/account/${id}`} isPreviewCell>
          <WrappedLeverage leverage={leverage} loading={loading} loadingFailed={loadingFailed} />
        </RowLink>
      ),
    },
    ...paymentButtonsColumn,
    ...webTerminalButtonColumn,
    ...brokereeManagerButtons,
  ];

  /* eslint-enable */

  const rowProps = (state, rowInfo) => ({
    onClick: () => !rowInfo.original.loadingFailed && onRowClick(rowInfo.original.id),
  });

  return (
    <div className="AccountTable">
      <BaseTable columns={columns} data={data} getTrProps={rowProps} />
      <BasicModal
        captionId="depositToDemoAccount"
        isOpen={!!selectedDemoAccount}
        onRequestClose={closeDemoDepositModal}>
        {!!selectedDemoAccount && (
          <DemoDepositForm accountId={selectedDemoAccount} onSuccessfulDeposit={closeDemoDepositModal} />
        )}
      </BasicModal>

      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

AccountTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      login: PropTypes.string.isRequired,
      accountType: LoginCell.propTypes.type,
      leverage: PropTypes.number,
      currency: PropTypes.string,
      balance: MoneyCell.propTypes.value,
      equity: MoneyCell.propTypes.value,
      loading: PropTypes.bool.isRequired,
      loadingFailed: PropTypes.bool,
    })
  ),
  history: PropTypes.object.isRequired,
  addons: PropTypes.array.isRequired,
  onRowClick: PropTypes.func.isRequired,
  getAccountTradeLink: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  equity: PropTypes.bool.isRequired,
  paymentButtons: PropTypes.bool,
  disableDepositForBrokereeManager: PropTypes.bool,
  areTransfersEnabled: PropTypes.bool,
  webTerminal: PropTypes.bool,
  isDepositEnabled: PropTypes.bool.isRequired,
  brokereeConfig: PropTypes.object,
  isWithdrawEnabled: PropTypes.bool.isRequired,
  theme: PropTypes.object,
};

AccountTable.defaultProps = {
  data: [],
  paymentButtons: true,
  areTransfersEnabled: false,
  webTerminal: false,
  disableDepositForBrokereeManager: false,
  brokereeConfig: null,
  theme: {},
};

export default compose(
  withTheme(),
  withRouter,
  connect(
    state => ({
      addons: state.interfaceConfig.addons,
      areTransfersEnabled: state.interfaceConfig.internalTransfers.accounts_enabled,
      equity: state.interfaceConfig.equityTradingHistoryDisplay.equity,
      isDepositEnabled: state.interfaceConfig.paymentSettings.isDepositEnabled,
      isWithdrawEnabled: state.interfaceConfig.paymentSettings.isWithdrawEnabled,
      lang: state.interfaceConfig.lang,
    }),
    { getAccountTradeLink: data => getAccountTradeLink.request(data) }
  )
)(AccountTable);
export { AccountTable };
