import { put, call, fork, take, takeEvery } from 'redux-saga/effects';
import { SubmissionError } from 'redux-form';

import { acceptError } from 'common/_redux';
import { sendYandexMetrikaEvent } from 'common/utils';
import { REQUEST } from 'redux-config/reduxHelpers';

import * as api from '../api';
import * as ActionTypes from './actions';

import { getTradingAccountSaga } from '../../_redux/commonSagas/sagas';

const replaceTemplates = (url, templates) =>
  url.replace(/{(.*?)}/g, (match, key) => (templates[key.trim()] !== undefined ? templates[key.trim()] : match));

export function* changeTradingAccountPasswordSaga(id, password) {
  try {
    const { status, data } = yield call(api.changeTradingAccountPassword, id, password);
    if (status < 300) {
      yield put(ActionTypes.changeTradingAccountPassword.success());
    } else {
      const error = data.detail ? { _error: data.detail } : data;
      yield put(ActionTypes.changeTradingAccountPassword.failure(new SubmissionError(error)));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.changeTradingAccountPassword.failure());
  }
}

export function* changeTradingAccountPasswordWatcherSaga() {
  while (true) {
    const {
      payload: { id, password },
    } = yield take(ActionTypes.changeTradingAccountPassword.REQUEST);
    yield call(changeTradingAccountPasswordSaga, id, password);
  }
}

export function* changeTradingAccountLeverageSaga(accountId, leverage) {
  try {
    const { status, data } = yield call(api.changeTradingAccountLeverage, accountId, leverage);
    if (status < 300) {
      yield put(ActionTypes.changeTradingAccountLeverage.success());
    } else {
      const error = data.detail ? { _error: data.detail } : data;
      yield put(ActionTypes.changeTradingAccountLeverage.failure(new SubmissionError(error)));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.changeTradingAccountLeverage.failure());
  }
}

export function* changeTradingAccountLeverageWatcherSaga() {
  while (true) {
    const {
      payload: { accountId, leverage },
    } = yield take(ActionTypes.changeTradingAccountLeverage.REQUEST);
    yield call(changeTradingAccountLeverageSaga, accountId, leverage);
  }
}

export function* createTradingAccountSaga({ payload: { accountTypeId, ...options } }) {
  try {
    const partnerCode = sessionStorage.getItem('partnerCode');
    const values = partnerCode ? { ...options.values, partnerCode } : { ...options.values };
    // we don't need to send agreement fields
    Object.keys(values)
      .filter(key => !!key.startsWith('agreement'))
      .forEach(key => delete values[key]);

    const { status, data } = yield call(api.createTradingAccount, accountTypeId, values);
    if (status < 300) {
      yield put(ActionTypes.createTradingAccount.success());
      yield put(ActionTypes.tradingAccountIsCreated.success(data));
      const EVENT_NAME = 'Create an account';
      sendYandexMetrikaEvent(EVENT_NAME);
    } else {
      const error = data.detail ? { _error: data.detail } : data;
      yield put(ActionTypes.createTradingAccount.failure(new SubmissionError(error)));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.createTradingAccount.failure());
  }
}

export function* createTradingAccountWatcherSaga() {
  yield takeEvery(ActionTypes.createTradingAccount.REQUEST, createTradingAccountSaga);
}

export function* createDemoDepositSaga({ amount, accountId }) {
  try {
    const { data, status } = yield call(api.createDemoDeposit, amount, accountId);
    if (status < 300) {
      yield put(ActionTypes.createDemoDeposit.success());
    } else {
      const error = data.detail ? { _error: data.detail } : data;
      yield put(ActionTypes.createDemoDeposit.failure(new SubmissionError(error)));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.createDemoDeposit.failure());
  }
}

export function* createDemoDepositWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.createDemoDeposit.REQUEST);
    yield call(createDemoDepositSaga, payload);
  }
}

export function* getDealStatsSaga() {
  try {
    const { data } = yield call(api.getDealStats);
    yield put(ActionTypes.getDealStats.success(data));
  } catch (e) {
    // yield put(acceptError(e));
    yield put(ActionTypes.getDealStats.failure());
  }
}

export function* getDealStatsWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_DEAL_STATS[REQUEST]);
    yield call(getDealStatsSaga);
  }
}

export function* getAccountTradeLinkSaga({ accountId, templates = {} }) {
  try {
    const { data } = yield call(api.getAccountTradeLink, accountId);
    yield put(ActionTypes.getAccountTradeLink.success(data));

    const finalTerminalLink = replaceTemplates(data, templates);
    window.open(finalTerminalLink, '_blank');
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getAccountTradeLink.failure());
  }
}

export function* getAccountTradeLinkWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_ACCOUNT_TRADE_LINK[REQUEST]);
    yield call(getAccountTradeLinkSaga, data);
  }
}

export function* getConvertationOptionsSaga({ id }) {
  try {
    const { data } = yield call(api.getConvertationOptions, id);
    yield put(ActionTypes.getConvertationOptions.success(data));
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getConvertationOptions.failure());
  }
}

export function* getConvertationOptionsWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_CONVERTATION_OPTIONS[REQUEST]);
    yield call(getConvertationOptionsSaga, data);
  }
}

export function* createAccountConvertationSaga(payload) {
  try {
    const { id, ...values } = payload;
    const { data, status } = yield call(api.createAccountConvertation, id, values);

    if (status < 300) {
      yield put(ActionTypes.createAccountConvertation.success(data));
      yield call(getTradingAccountSaga, { id });
      yield call(getConvertationOptionsSaga, { id });
    } else if (status === 409) {
      yield put(ActionTypes.createAccountConvertation.failure(new SubmissionError({ accountTypeId: data.error })));
    } else {
      yield put(ActionTypes.createAccountConvertation.failure());
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.createAccountConvertation.failure());
    throw e;
  }
}

export function* createAccountConvertationWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.createAccountConvertation.REQUEST);
    yield call(createAccountConvertationSaga, payload);
  }
}

export function* getLpaAgreementsSaga() {
  try {
    const { status, data } = yield call(api.getLpaAgreements);
    if (status < 300) {
      yield put(ActionTypes.getLpaAgreements.success(data));
    } else {
      yield put(ActionTypes.getLpaAgreements.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getLpaAgreements.failure());
  }
}

export function* getLpaAgreementsWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_LPA_AGREEMENTS[REQUEST]);
    yield call(getLpaAgreementsSaga);
  }
}

export function* setLpaAgreementsSaga(payload) {
  try {
    const { status, data } = yield call(api.setLpaAgreements, payload);
    if (status < 300) {
      yield put(ActionTypes.setLpaAgreements.success(data));
    } else {
      const error = data.detail ? { _error: data.detail } : data;
      yield put(ActionTypes.setLpaAgreements.failure(new SubmissionError(error)));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.setLpaAgreements.failure());
  }
}

export function* setLpaAgreementsWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.setLpaAgreements.REQUEST);
    yield call(setLpaAgreementsSaga, payload);
  }
}

export function* getOuterVerifyExternalLinkSaga({ program }) {
  try {
    const { data } = yield call(api.getOuterVerifyExternalLink, program);
    yield put(ActionTypes.getOuterVerifyExternalLink.success(data));
  } catch (e) {
    // yield put(acceptError(e));
    yield put(ActionTypes.getOuterVerifyExternalLink.failure());
  }
}

export function* getOuterVerifyExternalLinkWatcherSaga() {
  while (true) {
    const payload = yield take(ActionTypes.GET_OUTER_VERIFY_EXTERNAL_LINK[REQUEST]);
    yield call(getOuterVerifyExternalLinkSaga, payload);
  }
}

export default [
  fork(changeTradingAccountPasswordWatcherSaga),
  fork(changeTradingAccountLeverageWatcherSaga),
  fork(createTradingAccountWatcherSaga),
  fork(createDemoDepositWatcherSaga),
  fork(getDealStatsWatcherSaga),
  fork(getAccountTradeLinkWatcherSaga),
  fork(getConvertationOptionsWatcherSaga),
  fork(createAccountConvertationWatcherSaga),
  fork(getLpaAgreementsWatcherSaga),
  fork(setLpaAgreementsWatcherSaga),
  fork(getOuterVerifyExternalLinkWatcherSaga),
];
